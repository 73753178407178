import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import SignaturePad from "signature_pad"
import { useSelector } from "react-redux"
import imageApis from "../../apis/imageApis"
import { WidthToDP } from "../../utils/util"
import AppContainer from "../../containers/appContainer"
import Layout from "../component/common/layout"
import BasicPopup from "../../popups/basicPopup"
import styled from "styled-components"
import BackIcon from "../../images/back.png"

const DeliveryComplete = (props) => {
  const { postDeliveryComplete } = props
  const nativeData = props.location.state.data
  const screenWidth = useSelector((state) => state.ConfigReducer.screenWidth)
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [tap, setTap] = useState(0) //0:사진 1: 서명
  const [photo, setPhoto] = useState({ file: null, base64: null })
  const [signPadData, setSigPadData] = useState({ file: null, base64: null })

  useEffect(() => {
    window.NativeDataRecive = async (_data) => {
      if (_data.Type == "Back") {
        window.callNative(JSON.stringify({ Type: "DeliveryCompleteOpen", Data: { Status: 5, Type: 4 }, DeliveryData: nativeData }))
        navigate(-1)
      } else if (_data.Type == "TakePicture") {
        const file = dataURLtoFile("data:image/png;base64," + _data.CaptureData)
        setPhoto({
          file: file,
          base64: "data:image/png;base64," + _data.CaptureData,
        })
      }
    }
  }, [])

  useEffect(() => {
    // 서명패드 코드
    if (document.querySelector("canvas") !== null) {
      const signaturePad = new SignaturePad(document.querySelector("canvas"), {
        onEnd: () => {
          const file = dataURLtoFile(signaturePad.toDataURL(), "signData.jpg")
          setSigPadData({
            file: file,
            base64: signaturePad.toDataURL("image/jpeg"),
          }) // data:image/png;base64,iVBORw0K...
        },
        dotSize: 5,
        maxWidth: 5,
        minWidth: 5,
        backgroundColor: "rgb(255,255,255)",
      })
    }
  }, [tap === 1])

  const dataURLtoFile = (dataurl) => {
    const arr = dataurl.split(",")

    let mime = arr[0].match(/:(.*?);/)[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], `image.${mime.replace("image/", "")}`, {
      type: mime,
    })
  }

  const onClickSave = async () => {
    if (nativeData == null) return

    const params = {
      barcodes: [...nativeData.barcodes],
      addr: nativeData.addr,
      addrDetail: nativeData.addrDetail,
    }

    if (tap === 0) {
      if (photo.file === null) return setPopup({ open: true, text: "사진을 촬영해주세요." })
      const formData = new FormData()
      formData.append("image", photo.file)
      const imaegUpload = await imageApis.uploadImage(formData)
      params.photoUrl = imaegUpload
    } else if (tap === 1) {
      if (signPadData.file === null) return setPopup({ open: true, text: "서명을 해주세요." })
      const formData = new FormData()
      formData.append("image", signPadData.file)
      const imaegUpload = await imageApis.uploadImage(formData)
      params.signUrl = imaegUpload
    }

    const result = await postDeliveryComplete(params)
    console.log(result)
    if (result == true) setPopup({ open: true, text: "배송완료가 처리되었습니다." })
    else setPopup({ open: true, text: result.message })
  }

  return (
    <Layout
      title="배송완료"
      leftComponent={
        <button
          style={{ width: WidthToDP(90), height: WidthToDP(90) }}
          onClick={() => {
            window.callNative(JSON.stringify({ Type: "DeliveryCompleteOpen", Data: { Status: 5, Type: 4 }, DeliveryData: nativeData }))
            navigate(-1)
          }}
        >
          <img style={{ width: WidthToDP(15.4), height: WidthToDP(31) }} src={BackIcon} alt={"백 버튼"} />
        </button>
      }
    >
      <BasicPopup
        open={popup.open}
        onClose={() => {
          if (popup.text === "배송완료가 처리되었습니다.") {
            navigate("/app/main")
          }
          setPopup({ open: false, text: "" })
        }}
        content={popup.text}
        selectedValue={["확인"]}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TapBox
          active={tap === 0}
          onClick={() => {
            setPhoto({ file: null, base64: null })
            setSigPadData({ file: null, base64: null })
            setTap(0)
          }}
        >
          <TapText active={tap === 0}>사진</TapText>
        </TapBox>

        <TapBox
          active={tap === 1}
          onClick={() => {
            setPhoto({ file: null, base64: null })
            setSigPadData({ file: null, base64: null })
            setTap(1)
          }}
        >
          <TapText active={tap === 1}>서명</TapText>
        </TapBox>
      </div>
      {tap === 0 && (
        <CamaraBox>
          {photo.base64 != null && <img style={{ width: screenWidth, height: screenWidth }} src={photo.base64} alt={"사진"} />}
          {photo.base64 == null && <div style={{ backgroundColor: "#fff", width: screenWidth, height: screenWidth }}></div>}
        </CamaraBox>
      )}
      {tap === 1 && (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <canvas width={screenWidth} height={screenWidth} />
          <p
            style={{
              position: "absolute",
              bottom: `${WidthToDP(45.4)}px`,
              color: "#acb0bb",
              fontSize: `${WidthToDP(32)}px`,
              fontFamily: "NanumSquareBold",
              lineHeight: `${WidthToDP(38.4)}px`,
              letterSpacing: `${WidthToDP(-0.96)}px`,
            }}
          >
            이곳에 서명을 해 주세요
          </p>
        </div>
      )}
      <div style={{ width: "100%", height: "100%", backgroundColor: "#eef2f7" }}></div>
      {tap === 0 && photo.base64 === null && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: `calc( 100% - ${WidthToDP(40)}px`,
            marginBottom: `${WidthToDP(20)}px`,
            marginLeft: `${WidthToDP(20)}px`,
            marginRight: `${WidthToDP(20)}px`,
          }}
        >
          <button
            style={{
              backgroundColor: "#00adee",
              width: "100%",
              height: `${WidthToDP(90)}px`,
              borderRadius: `${WidthToDP(20)}px`,
            }}
            onClick={() => window.callNative(JSON.stringify({ Type: "TakePicture" }))}
          >
            <P32Text color={"#fff"}>사진 촬영</P32Text>
          </button>
        </div>
      )}
      {tap === 0 && photo.base64 && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              position: "absolute",
              bottom: 0,
              width: `calc( 100% - ${WidthToDP(40)}px`,
              marginBottom: `${WidthToDP(20)}px`,
              marginLeft: `${WidthToDP(20)}px`,
              marginRight: `${WidthToDP(20)}px`,
            }}
          >
            <button
              style={{
                display: "flex",
                flex: 2,
                height: `${WidthToDP(90)}px`,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                borderRadius: `${WidthToDP(20)}px`,
              }}
              onClick={() => {
                setPhoto({ file: null, base64: null })
              }}
            >
              <P32Text color={"#00adee"}>재촬영</P32Text>
            </button>

            <button
              style={{
                display: "flex",
                flex: 5,
                backgroundColor: "#00adee",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: `${WidthToDP(20)}px`,
                borderRadius: `${WidthToDP(20)}px`,
              }}
              onClick={onClickSave}
            >
              <P32Text color={"#fff"}>저장</P32Text>
            </button>
          </div>
        </>
      )}

      {tap === 1 && (
        <>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "fit-content",
              padding: "20px",
            }}
          >
            <button
              style={{
                backgroundColor: "#00adee",
                width: "100%",
                height: `${WidthToDP(90)}px`,
                borderRadius: `${WidthToDP(20)}px`,
              }}
              onClick={onClickSave}
            >
              <P32Text color={"#fff"}>저장</P32Text>
            </button>
          </div>
        </>
      )}
    </Layout>
  )
}

export default AppContainer(DeliveryComplete)

const TapBox = styled.button`
  background-color: ${(props) => (props.active ? "#00adee" : "#eef2f7")};
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  padding-top: ${WidthToDP(25.9)}px;
  padding-bottom: ${WidthToDP(25.1)}px;
`
const TapText = styled.p`
  font-family: NanumSquareBold;
  font-size: ${WidthToDP(32)}px;
  line-height: ${WidthToDP(38.4)}px;
  letter-spacing: ${WidthToDP(-0.96)}px;
  color: ${(props) => (props.active ? "#fff" : "#8a8e98")};
`
const P32Text = styled.p`
  font-family: NanumSquareBold;
  font-size: ${WidthToDP(32)}px;
  line-height: ${WidthToDP(38.4)}px;
  letter-spacing: ${WidthToDP(-0.96)}px;
  color: ${(props) => props.color || "#000"};
`

const CamaraBox = styled.div`
  & > video {
    object-fit: fill;
  }
`
