import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import moment from "moment"
import { WidthToDP, androidBackHandler } from "../../utils/util"
import AppContainer from "../../containers/appContainer"

import InputBox from "../component/common/inputBox"

import Layout from "../component/common/layout"
import BackIcon from "../../images/back.png"
import SearchIcon from "../../images/search.png"

const Up = (props) => {
  
  const { pouchTracks, pouchTracksTotal, getPouchTracks, getCustList, custList } = props

  const [custSeq, setCustSeq] = useState(0) //[{ seq: -1, name: "선택" }]) 

  const [custListCombo, setCustListCombo] = useState([{ seq: 0, name: "" }])  
  
  useEffect(() => {
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      navigate(-1)
    })

    getData()
  }, [])

  const getData = async () => {

    await getPouchTracks({ custSeq })

    await getCustList()

  }

  useEffect(() => {
    
    const temp = custList.map( row => ({ seq : row.custSeq, name : row.custName }) )

    setCustListCombo( [{ seq: 0, name: "" }].concat(temp) )

  }, [custList])

  const onChangeCust = async ( value ) => {
    
    setCustSeq( value )

    await getPouchTracks({ custSeq : value })

  }

  return (
    <Layout
      title={"행낭 상차"}
      leftComponent={
        <button style={{ width: WidthToDP(90), height: WidthToDP(90) }} onClick={() => navigate(-1)}>
          <img style={{ width: WidthToDP(15.4), height: WidthToDP(31) }} src={BackIcon} alt={"백 버튼"} />
        </button>
      }
      rightComponent={
        <button style={{ width: WidthToDP(90), height: WidthToDP(90) }} onClick={() => navigate("/app/search")}>
          <img style={{ width: WidthToDP(40), height: WidthToDP(40) }} src={SearchIcon} alt={"백 버튼"} />
        </button>
      }
    >

      <span style={{ padding: WidthToDP(30) }}>
        <FlexBox justifyContent={"space-between"}>
          <DateP>{moment().format("YYYY.MM.DD")}</DateP>
          <InputBox type={"select"} label="" marginLeft={10} 
            item={custListCombo} 
            value={custSeq > 0 ? custListCombo.filter( row => row.seq == custSeq)[0].name : ""} setValue={onChangeCust}></InputBox>
          {/* ={opType}= */}
        </FlexBox>
      </span>

      <TakeBox>
        <table>
          <thead>
            <tr>
              <th>번호</th>
              <th>행낭코드</th>
              <th>거래처명</th>
              <th>발신지점명</th>
              <th>수신지점명</th>
              <th>발송지사</th>
              <th>도착지사</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            {pouchTracks
              // .sort((a, b) => new Date(b.regDatetime) - new Date(a.regDatetime))
              .map((i, idx) => (
                
                <tr key={idx} style={{backgroundColor:i.isTrack == '1' ? '#1C9BFD' : 'white'}} >

                  {/* <td>{pouchTracksTotal - idx}</td>
                  <td>{i.pouch.pouchNo}</td>
                  <td>{i.pouch.sendPoint.pointName}</td>
                  <td>{i.pouch.sendBranch.branchName}</td>
                  <td>{i.pouch.recvBranch.branchName}</td>
                  <td>{i.pouch.remark}</td> */}

                  <td>{pouchTracks.length - idx}</td>
                  <td>{i.pouchNo}</td>
                  <td>{i.custName}</td>
                  <td>{i.sendPointName}</td>
                  <td>{i.recvPointName}</td>
                  <td>{i.sendBranchName}</td>
                  <td>{i.recvBranchName}</td>
                  <td>{(i.isTrack == '1' ? i.scanTime+', '+i.location : ' ')+i.remark}</td>

                </tr>

              ))}
          </tbody>
        </table>
      </TakeBox>

      {/* <ScanButtonBox>
        <ScanButton>스캔</ScanButton>
      </ScanButtonBox> */}
    </Layout>
  )
}

export default AppContainer(Up)

const FlexBox = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  margin-top: ${(props) => props.marginTop}px;
`
const DateP = styled.p`
  color: #35363a;
  font-size: ${WidthToDP(40)}px;
  font-family: NanumSquareExtraBold;
  line-height: ${WidthToDP(48)}px;
`
const TakeBox = styled.div`
  background-color: #f5f6f8;
  overflow-x: auto;
`
const ScanButtonBox = styled.div`
  position: absolute;
  bottom: ${WidthToDP(20)}px;
  width: 100%;
  padding: 0 ${WidthToDP(20)}px;
`
const ScanButton = styled.button`
  background-color: #00adee;
  width: 100%;
  height: ${WidthToDP(90)}px;
  border-radius: ${WidthToDP(20)}px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: ${WidthToDP(32)}px;
  font-family: NanumSquareBold;
  line-height: ${WidthToDP(38.4)}px;
  letter-spacing: ${WidthToDP(-0.96)}px;
`
