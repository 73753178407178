import React, { useEffect } from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Page404 from "./404"
import Root from "../components/page/root"
import ScanLargeBag from "../components/page/scanLargeBag"
import ScanSmallBag from "../components/page/scanSmallBag"
import DeliveryComplete from "../components/page/deliveryComplete"
import DeliveryCompleteFinish from "../components/page/deliveryCompleteFinish"
import Login from "../components/page/login"
import Main from "../components/page/main"
import Search from "../components/page/search"
import Take from "../components/page/take"
import Up from "../components/page/up"
import "../components/layout.css"

// test
import { useDispatch } from "react-redux"
import { setScreenWidth } from "../actions/configAction"

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // 초기 브라우저 width 셋팅
    dispatch(setScreenWidth(window.innerWidth))
    // 브라우저 width 조절 시 셋팅
    window.addEventListener("resize", function (e) {
      dispatch(setScreenWidth(e.target.innerWidth))
    })
  }, [])

  return (
    <Router basepath="/app" style={{ width: "100%", height: "100%" }}>
      <Root path="/" />
      <Login path="/login" />
      <PrivateRoute path={"/main"} component={Main} />
      <PrivateRoute path={"/search"} component={Search} />
      {/* <PrivateRoute path={"/take"} component={Take} /> */}
      <PrivateRoute path={"/up"} component={Up} />
      {/* <PrivateRoute path={"/scanLargeBag"} component={ScanLargeBag} />
      <PrivateRoute path={"/scanSmallBag"} component={ScanSmallBag} /> */}
      {/* <PrivateRoute path={"/deliveryComplete"} component={DeliveryComplete} /> */}
      <PrivateRoute path={"/deliveryCompleteFinish"} component={DeliveryCompleteFinish} />
      {/* 설정 이외의 경로로 들어갈 경우 404 페이지 띄움 */}
      <Page404 path="/*" />
    </Router>
  )
}

export default App
