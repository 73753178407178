import api from "../utils/api"
import cookie from "react-cookies"

const uploadImage = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.post(`/upload/image`, {
    token,
    body: data,
  })
  return result.data.data.image
}

const uploadImages = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.post(`/upload/images`, {
    token,
    body: data,
  })
  return result.data.data.images
}

export default {
  uploadImage,
  uploadImages,
}
