import React from "react"
import styled from "styled-components"
import { WidthToDP } from "../../../utils/util"

const Header = (props) => {
  const { backgroundColor, color, isHeader = true, title, leftComponent = null, rightComponent = null } = props

  return (
    <Container backgroundColor={backgroundColor} on={isHeader ? "on" : "off"}>
      <div style={{ display: "flex", flex: 1, justifyContent: "flex-start", alignItems: "center" }}>{leftComponent ? leftComponent : null}</div>
      <TitleBox color={color}>
        <p>{title}</p>
      </TitleBox>
      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "center" }}> {rightComponent ? rightComponent : null}</div>
    </Container>
  )
}

export default Header

const Container = styled.div`
  background-color: ${(props) => props.backgroundColor};
  display: ${(props) => (props.on === "on" ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${WidthToDP(90)}px;
`

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 100%;
  & > p {
    color: ${(props) => props.color};
    font-size: ${WidthToDP(33.7)}px;
    font-family: NanumSquareBold;
    line-height: ${WidthToDP(40.5)}px;
    letter-spacing: ${WidthToDP(-1.01)}px;
  }
`
