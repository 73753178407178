import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import cookie from "react-cookies"
import { WidthToDP, callNative } from "../../utils/util"
import SignContainer from "../../containers/signContainer"
import Layout from "../component/common/layout"
import BasicPopup from "../../popups/basicPopup"
import Logo from "../../images/intro.png"
import User from "../../images/user.png"
import Pw from "../../images/pw.png"

const Login = (props) => {
  const { signIn } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [id, setId] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    const tk = cookie.load("app_token", { path: "/" })

    if (tk === null || tk === undefined) {
      // 토큰 없음
      if (localStorage.getItem("auto_login") !== null) {
        // 자동로그인
        cookie.save("app_token", localStorage.getItem("auto_login"), { path: "/" })
        callNative("ApiToken", { ApiToken: localStorage.getItem("auto_login") })
        navigate("/app/main", { replace: true })
      }
    } else {
      // 토큰 있음
      navigate("/app/main", { replace: true })
    }
  }, [])

  async function loginFunction() {
    if (id === "" || password === "") {
      setPopup({ open: true, text: "아이디와 비밀번호를 모두 입력해주세요." })
    } else {
      const result = await signIn({ id, password })
      if (result) {
        navigate("/app/main")
      } else {
        setPopup({ open: true, text: "해당하는 로그인 정보가 없습니다." })
      }
    }
  }

  return (
    <Layout isHeader={false}>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={["확인"]} />
      <Container>
        <img style={{ width: WidthToDP(250), height: "auto", marginBottom: WidthToDP(140) }} src={Logo} alt={"로고 이미지"} />
        <InputBox>
          <img style={{ width: WidthToDP(28), height: WidthToDP(28), marginRight: WidthToDP(12.1) }} src={User} alt={"아이디 아이콘"} />
          <Input type={"text"} placeholder={"아이디를 입력해주세요"} value={id} onChange={(e) => setId(e.target.value)} />
        </InputBox>
        <InputBox style={{ marginTop: WidthToDP(36) }}>
          <img style={{ width: WidthToDP(26), height: WidthToDP(32), marginRight: WidthToDP(12.1) }} src={Pw} alt={"아이디 아이콘"} />
          <Input type={"password"} placeholder={"비밀번호를 입력해주세요"} value={password} onChange={(e) => setPassword(e.target.value)} />
        </InputBox>
        <LoginButton onClick={loginFunction}>로그인</LoginButton>
        <FindIDPWDButton>아이디, 비밀번호 문의</FindIDPWDButton>
      </Container>
    </Layout>
  )
}

export default SignContainer(Login)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`
const InputBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${WidthToDP(500)}px;
  height: ${WidthToDP(77)}px;
  border-bottom: 1px solid #dbdee5;
`
const Input = styled.input`
  caret-color: #00adee;
  flex: 1;
  color: #35363a;
  font-size: ${WidthToDP(32)}px;
  font-family: NanumSquareRegular;
  line-height: ${WidthToDP(38.4)}px;
  letter-spacing: ${WidthToDP(-0.96)}px;
`
const LoginButton = styled.button`
  background-color: #00adee;
  width: ${WidthToDP(500)}px;
  height: ${WidthToDP(90)}px;
  margin-top: ${WidthToDP(40)}px;
  border-radius: ${WidthToDP(20)}px;
  color: #fff;
  font-size: ${WidthToDP(32)}px;
  font-family: NanumSquareBold;
  line-height: ${WidthToDP(38.4)}px;
  letter-spacing: ${WidthToDP(-0.96)}px;
`
const FindIDPWDButton = styled.button`
  background-color: #ffffff;
  width: ${WidthToDP(500)}px;
  height: ${WidthToDP(90)}px;
  margin-top: ${WidthToDP(40)}px;
  margin-bottom: ${WidthToDP(16)}px;
  border: 1px solid #00adee;
  border-radius: ${WidthToDP(20)}px;
  color: #00adee;
  font-size: ${WidthToDP(32)}px;
  font-family: NanumSquareBold;
  line-height: ${WidthToDP(38.4)}px;
  letter-spacing: ${WidthToDP(-0.96)}px;
`
