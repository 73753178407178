import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import Picker from "react-mobile-picker-scroll"
import { WidthToDP } from "../../../utils/util"

const DatePicker = (props) => {
  const { open, onClose, date, setDate } = props
  const [year, setYear] = useState(moment().get("year") + "년")
  const [month, setMonth] = useState(moment().get("month") + 1 + "월")
  const [day, setDay] = useState(moment().get("date") + "일")
  const [days, setDays] = useState(generateDate())

  useEffect(() => {
    if (date !== null) {
      setYear(date.get("year") + "년")
      setMonth(date.get("month") + 1 + "월")
      setDay(date.get("date") + "일")
    }
  }, [open])

  function generateDate() {
    const temp = []
    for (let i = 1; i <= 31; i++) {
      if (date !== null) {
        if (i > moment(date).endOf("month").date()) return
        temp.push(i + "일")
      } else {
        if (i > moment().endOf("month").date()) return
        temp.push(i + "일")
      }
    }
    return temp
  }

  const optionGroups = {
    year: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5].map((i) => moment().get("year") + i + "년"),
    month: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
    day: days,
  }

  if (date === null) return null

  return (
    <Container open={open}>
      <FlexBox>
        <Button color={"#aaaaaa"} onClick={onClose}>
          <p>취소</p>
        </Button>
        <Button
          color={"#0084d1"}
          onClick={() => {
            setDate(moment(year.replace("년", "-") + month.replace("월", "-") + day.replace("일", "")))
            onClose()
          }}
        >
          <p>선택</p>
        </Button>
      </FlexBox>
      <Picker
        optionGroups={optionGroups}
        valueGroups={{ year, month, day }}
        onChange={(name, value) => {
          if (name === "year") setYear(value)
          else if (name === "month") {
            setMonth(value)
            const temp = []
            const tempDate = moment(year.replace("년", "-") + value.replace("월", "-") + 1)
            for (let i = 1; i <= 31; i++) {
              if (i > tempDate.endOf("month").date()) break
              temp.push(i + "일")
            }
            setDays([...temp])
          } else if (name === "day") setDay(value)
        }}
      />
    </Container>
  )
}

export default DatePicker

const Container = styled.div`
  background-color: #f1f3f6;
  position: absolute;
  bottom: ${(props) => (props.open ? 0 : WidthToDP(-9999))}px;
  width: 100%;
  height: ${WidthToDP(420)}px;
  transition: 0.5s;
  box-shadow: 0px -5px 30px 0px rgba(0, 0, 0, 0.3);

  /* picker style */
  & .picker-inner {
    padding: 0 ${WidthToDP(190)}px;
  }
  & .picker-scroller {
    outline: none;
  }
  & .picker-container .picker-column .picker-item {
    width: ${WidthToDP(120)}px;
    padding: 0px;
    color: #22272a;
    font-size: ${WidthToDP(28)}px;
    line-height: ${WidthToDP(33.6)}px;
    letter-spacing: ${WidthToDP(-0.56)}px;
    font-family: NanumSquareRegular;
    text-align: right;
  }
  & .picker-container .picker-column .picker-item-selected {
    color: #000000;
    font-size: ${WidthToDP(35)}px;
    font-family: NanumSquareBold;
    line-height: ${WidthToDP(44.4)}px;
    letter-spacing: ${WidthToDP(-0.74)}px;
  }
  & .picker-highlight {
    background: rgba(255, 255, 255, 0.5);
    height: ${WidthToDP(66)}px;
    opacity: 0.5;
    box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.25);
  }
`

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${WidthToDP(26.8)}px;
`

const Button = styled.button`
  & > p {
    color: ${(props) => props.color};
    font-size: ${WidthToDP(28)}px;
    font-family: NanumSquareRegular;
    line-height: ${WidthToDP(33.6)}px;
    letter-spacing: ${WidthToDP(-0.56)}px;
  }
`
