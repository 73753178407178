import React, { useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import cookie from "react-cookies"
import { WidthToDP, callNative } from "../../utils/util"
import AppContainer from "../../containers/appContainer"
import Layout from "../component/common/layout"
import SearchIcon from "../../images/search.png"
import PickupRed from "../../images/pickupRed.png"
import PickupBlue from "../../images/pickupBlue.png"
import Send from "../../images/send.png"
import Sangcha from "../../images/sangcha.png"
import Hacha from "../../images/hacha.png"

import Recieve from "../../images/scan.png"
import Category from "../../images/divide.png"

import Complete from "../../images/complete.png"

const Main = (props) => {
  const { user, userType } = props

  useEffect(() => {
    window.NativeDataRecive = async (data) => {
      console.log(JSON.stringify(data))
      if (data.Type === "DeliveryCompleted") {
        navigate("/app/deliveryCompleteFinish", {
          state: { data: data.DeliveryData },
        })
      }
    }
  }, [])

  const renderMenu = () => {
    switch (userType) {
      case 1:
        return (
          <>
            <FlexBox>
              <Button onClick={() => callNative("ScanOpen", { Status: 10, Type: 1 })}>
                <Type1P color={"#ff550a"}>
                  행낭<span>(대낭/행낭)</span>
                </Type1P>
                <Type1P color={"#000000"}>픽업</Type1P>
                <Img width={WidthToDP(190)} right={WidthToDP(33)} bottom={WidthToDP(35)} src={PickupRed} alt={"픽업 이미지"} />
              </Button>
              <Button onClick={() => callNative("ScanOpen", { Status: 10, Type: 3 })}>
                <Type1P color={"#0084d1"}>소화물</Type1P>
                <Type1P color={"#000000"}>픽업</Type1P>
                <Img width={WidthToDP(190)} right={WidthToDP(33)} bottom={WidthToDP(35)} src={PickupBlue} alt={"픽업 이미지"} />
              </Button>
            </FlexBox>
            <FlexBox marginTop={WidthToDP(30)}>
              <Button onClick={() => callNative("ScanOpen", { Status: 1, Type: 1 })}>
                <Type1P color={"#ff550a"}>
                  행낭<span>(대낭/행낭)</span>
                </Type1P>
                <Type1P color={"#000000"}>픽업</Type1P>
                <Img width={WidthToDP(190)} right={WidthToDP(33)} bottom={WidthToDP(35)} src={PickupRed} alt={"픽업 이미지"} />
              </Button>
              <Button onClick={() => callNative("ScanOpen", { Status: 1, Type: 3 })}>
                <Type1P color={"#0084d1"}>소화물</Type1P>
                <Type1P color={"#000000"}>발신</Type1P>
                <Img width={WidthToDP(190)} right={WidthToDP(30)} bottom={WidthToDP(38)} src={Send} alt={"픽업 이미지"} />
              </Button>
            </FlexBox>
            <FlexBox marginTop={WidthToDP(30)}>
              <Button onClick={() => callNative("DeliveryCompleteOpen", { Status: 5, Type: 4 })}>
                <Type3P color={"#000000"}>배송완료</Type3P>
                <Img width={WidthToDP(280)} right={WidthToDP(-50)} bottom={WidthToDP(35)} src={Complete} alt={"픽업 이미지"} />
              </Button>
              <Button style={{ backgroundColor: "#ffffff00" }}></Button>
            </FlexBox>
          </>
        )
      case 3:
        return (
          <>
            <FlexBox>
              <Button onClick={() => callNative("ScanOpen", { Status: 2, Type: 4 })}>
                <Type2P color={"#000000"}>상차</Type2P>
                <Img width={WidthToDP(242)} right={WidthToDP(39)} bottom={WidthToDP(50)} src={Sangcha} alt={"픽업 이미지"} />
              </Button>
              <Button onClick={() => callNative("ScanOpen", { Status: 9, Type: 4 })}>
                <Type2P color={"#000000"}>하차</Type2P>
                <Img width={WidthToDP(242)} right={WidthToDP(39)} bottom={WidthToDP(50)} src={Hacha} alt={"픽업 이미지"} />
              </Button>
            </FlexBox>
          </>
        )
      case 2:
        return (
          <>
            <FlexBox>
              <Button onClick={() => callNative("ScanOpen", { Status: 4, Type: 5 })}>
                <Type3P color={"#000000"}>스캔</Type3P>
                <Img width={WidthToDP(250)} right={WidthToDP(61.5)} bottom={WidthToDP(10)} src={Recieve} alt={"픽업 이미지"} />
              </Button>
              {/* <Button onClick={() => callNative("ScanOpen", { Status: 3, Type: 4 })}>
                <Type3P color={"#000000"}>발신</Type3P>
                <Img width={WidthToDP(190)} right={WidthToDP(61.5)} bottom={WidthToDP(50)} src={Send} alt={"픽업 이미지"} />
              </Button> */}
              <Button onClick={() => callNative("ScanOpen", { Status: 7, Type: 1 })}>
                <Type3P color={"#000000"}>
                  분류
                  {/* <span>{"\n"}(대낭/행낭)</span> */}
                </Type3P>
                <Img width={WidthToDP(350)} right={WidthToDP(-10)} bottom={WidthToDP(-10)} src={Category} alt={"픽업 이미지"} />
              </Button>
            </FlexBox>
            <FlexBox marginTop={WidthToDP(30)}>
              
              <Button onClick={() => callNative("DeliveryCompleteOpen", { Status: 5, Type: 4 })}>
                <Type3P color={"#000000"}>배송완료</Type3P>
                <Img width={WidthToDP(280)} right={WidthToDP(-50)} bottom={WidthToDP(35)} src={Complete} alt={"픽업 이미지"} />
              </Button>
              <Button onClick={() => navigate("/app/up")}>
                <Type3P color={"#000000"}>행낭상차</Type3P>
                <Type3P color={"#000000"}>목록확인</Type3P>
                <Img width={WidthToDP(220)} right={WidthToDP(20)} bottom={WidthToDP(50)} src={Sangcha} alt={"픽업 이미지"} />
              </Button>

            </FlexBox>
            {/* <FlexBox marginTop={WidthToDP(30)}>
              <Button onClick={() => navigate("/app/up")}>
                <Type3P color={"#000000"}>행낭상차</Type3P>
                <Type3P color={"#000000"}>목록확인</Type3P>
                <Img width={WidthToDP(220)} right={WidthToDP(20)} bottom={WidthToDP(50)} src={Sangcha} alt={"픽업 이미지"} />
              </Button>
              <Button style={{ backgroundColor: "#ffffff00" }}></Button>
            </FlexBox> */}
          </>
        )
      default:
        return null
    }
  }

  return (
    <Layout
      title={"메인"}
      backgroundColor={"#0c2d4e"}
      color={"#fff"}
      leftComponent={
        <button
          style={{
            width: WidthToDP(200),
            height: WidthToDP(90),
            color: "#fff",
            fontSize: WidthToDP(33.7),
            fontFamily: "NanumSquareBold",

            lineHeight: `${WidthToDP(40.5)}px`,
            letterSpacing: `${WidthToDP(-1.01)}px`,
          }}
          onClick={() => {
            cookie.remove("app_token", { path: "/" })
            localStorage.removeItem("auto_login")
            navigate("/app/login")
          }}
        >
          로그아웃
        </button>
      }
      rightComponent={
        <button style={{ width: WidthToDP(90), height: WidthToDP(90) }} onClick={() => navigate("/app/search")}>
          <img style={{ width: WidthToDP(40), height: WidthToDP(40) }} src={SearchIcon} alt={"검색"} />
        </button>
      }
    >
      <Container>{renderMenu()}</Container>
    </Layout>
  )
}

export default AppContainer(Main)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: ${(props) => props.marginTop}px;
`
const Button = styled.button`
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${WidthToDP(330)}px;
  height: ${WidthToDP(330)}px;
  padding: ${WidthToDP(54.9)}px ${WidthToDP(33)}px ${WidthToDP(35)}px ${WidthToDP(40.2)}px;
  border-radius: ${WidthToDP(12)}px;
`
const Type1P = styled.p`
  color: ${(props) => props.color};
  font-size: ${WidthToDP(40)}px;
  font-family: NanumSquareBold;
  line-height: ${WidthToDP(48)}px;
  letter-spacing: ${WidthToDP(-1.2)}px;
  text-align: left;
  & > span {
    margin-left: ${WidthToDP(10)}px;
    color: #8b98bc;
    font-size: ${WidthToDP(30)}px;
    letter-spacing: ${WidthToDP(-0.9)}px;
  }
`
const Type2P = styled(Type1P)`
  font-size: ${WidthToDP(50)}px;
  letter-spacing: ${WidthToDP(-1.5)}px;
`
const Type3P = styled(Type1P)`
  font-size: ${WidthToDP(44)}px;
  line-height: ${WidthToDP(52)}px;
  letter-spacing: ${WidthToDP(-1.32)}px;
  white-space: pre;
`
const Img = styled.img`
  position: absolute;
  bottom: ${(props) => props.bottom}px;
  right: ${(props) => props.right}px;
  width: ${(props) => props.width}px;
  height: auto;
`
