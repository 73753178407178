import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import moment from "moment"
import { WidthToDP, GetStatus, androidBackHandler } from "../../utils/util"
import AppContainer from "../../containers/appContainer"
import Layout from "../component/common/layout"
import DatePicker from "../component/common/datePicker"
import BackIcon from "../../images/back.png"
import CalendarIcon from "../../images/calendar.png"
import SearchIcon from "../../images/search.png"
import DropIcon from "../../images/drop_blue.png"
import CalendarPopup from "../component/common/calendarPopup"
import BasicPopup from "../../popups/basicPopup"

const Search = (props) => {
  const { freightTracks, freightTracksTotal, pouchTracks, pouchTracksTotal, getFreightTracks, getPouchTracks } = props
  const [tab, setTab] = useState(0)
  const [drop, setDrop] = useState(false)
  const [startDate, setStartDate] = useState(moment().startOf("month"))
  const [endDate, setEndDate] = useState(moment().endOf("month"))
  const [datePickerOpen, setDatePickerOpen] = useState({ open: false, date: null, setDate: () => {} })
  // 검색 컴포넌트
  const [current, setCurrent] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [searchIndex, setSearchIndex] = useState(0)
  const [popup, setPopup] = useState(false)
  const [messageIndex, setMessageIndex] = useState(0)
  const messageArr = ["", "검색항목을 입력하세요", "검색어를 두자이상 입력해주세요"]
  //onClick ..
  const onClickSearch = async () => {
    if (searchIndex === 0) {
      setMessageIndex(1)
      setPopup(true)
      return
    } else if (searchText.length < 2) {
      setMessageIndex(2)
      setPopup(true)
      return
    }
    let temp = { page: 0, count: 100, startDate: moment(startDate).format("YYYY-MM-DD"), endDate: moment(endDate).format("YYYY-MM-DD") }
    if (searchIndex !== 0 && searchText.length >= 2) {
      temp = { ...temp, searchType: searchIndex, search: searchText }
    }
    if (tab === 0) {
      await getPouchTracks({ ...temp })
    } else {
      await getFreightTracks({ ...temp })
    }
  }
  useEffect(() => {
    //안드로이드 백 핸들러
    androidBackHandler(() => {
      navigate(-1)
    })

    getData()
    setSearchText("")
    setSearchIndex(0)
  }, [tab])

  const getData = async () => {
    if (tab === 0) {
      await getPouchTracks({ page: 0, count: 100, startDate: moment(startDate).format("YYYY-MM-DD"), endDate: moment(endDate).format("YYYY-MM-DD") })
    } else if (tab === 1) {
      await getFreightTracks({
        page: 0,
        count: 100,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      })
    }
  }

  return (
    <Layout
      title={"검색"}
      leftComponent={
        <button style={{ width: WidthToDP(90), height: WidthToDP(90) }} onClick={() => navigate(-1)}>
          <img style={{ width: WidthToDP(15.4), height: WidthToDP(31) }} src={BackIcon} alt={"백 버튼"} />
        </button>
      }
    >
      <BasicPopup open={popup} onClose={() => setPopup(false)} content={messageArr[messageIndex]} selectedValue={["확인"]} />
      {/* TAB */}
      <FlexBox>
        <Tab select={tab === 0} onClick={() => setTab(0)}>
          행낭
        </Tab>
        <Tab select={tab === 1} onClick={() => setTab(1)}>
          소화물
        </Tab>
      </FlexBox>

      {/* SEARCH */}
      <SearchBox>
        <FlexBox style={{ position: "relative" }}>
          <ScanP>스캔일자</ScanP>
          <div style={{ display: "flex", flex: 1, justifyContent: "space-between", alignItems: "center" }}>
            <DateButton
              onClick={() => setDatePickerOpen({ open: true, date: startDate, setDate: setStartDate })}
              onBlur={() => setDatePickerOpen({ open: false, date: null, setDate: () => {} })}
            >
              <img src={CalendarIcon} alt={"달력 아이콘"} />
              <p>{startDate.format("YYYY.MM.DD")}</p>
            </DateButton>
            <p> ~ </p>
            <DateButton
              onClick={() => setDatePickerOpen({ open: true, date: endDate, setDate: setEndDate })}
              onBlur={() => setDatePickerOpen({ open: false, date: null, setDate: () => {} })}
            >
              <img src={CalendarIcon} alt={"달력 아이콘"} />
              <p>{endDate.format("YYYY.MM.DD")}</p>
            </DateButton>
          </div>
          <CalendarPopup
            open={datePickerOpen.open}
            date={datePickerOpen.date}
            setDate={datePickerOpen.setDate}
            setCalendarPopup={setDatePickerOpen}
          />
        </FlexBox>
        <FlexBox marginTop={WidthToDP(30)}>
          <SelectBox>
            <SelectButton color={searchIndex === 0 ? "#acb0bb" : "#35363a"} onClick={() => setDrop(!drop)} onBlur={() => setDrop(false)}>
              <p>{["선택", "행낭코드", "거래처지점명", "발송지사", "도착지사", "스캔일자", "스캔담당", "위치", "상태"][searchIndex]}</p>
              <img src={DropIcon} alt={"파란 드롭 이미지"} />
            </SelectButton>
            <OptionBox open={drop}>
              {["선택", "행낭코드", "거래처지점명", "발송지사", "도착지사", "스캔일자", "스캔담당", "위치", "상태"].map((option, optionIndex) => (
                <Option
                  key={optionIndex}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={(e) => {
                    setSearchIndex
                    setSearchIndex(optionIndex)
                    setDrop(false)
                  }}
                >
                  {option}
                </Option>
              ))}
            </OptionBox>
          </SelectBox>

          <Input type={"text"} placeholder={"검색어를 두자 이상 입력하세요."} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          <SearchButton onClick={onClickSearch}>
            <img src={SearchIcon} alt={"검색 아이콘"} />
          </SearchButton>
        </FlexBox>
      </SearchBox>

      {/* RESULT */}
      <SearchResultBox>
        <table>
          <thead>
            <tr>
              <th>번호</th>
              <th>행낭코드</th>
              <th>거래처 지점명</th>
              <th>발송지사</th>
              <th>도착지사</th>
              <th>스캔일자</th>
              <th>스캔담당</th>
              <th>위치</th>
              <th>상태</th>
              <th>사진서명</th>
            </tr>
          </thead>
          <tbody>
            {(tab === 0 ? pouchTracks : freightTracks).map((v, i) => (
              <tr key={i}>
                <td>{tab === 0 ? pouchTracksTotal - (current - 1) * 20 - i : freightTracksTotal - (current - 1) * 20 - i}</td>
                <td>{tab === 0 ? v.pouch.pouchNo : v.freight.freightNo}</td>
                <td>{tab === 0 ? v.pouch.sendPoint && v.pouch.sendPoint.pointName : v.freight.sendPoint && v.freight.sendPoint.pointName}</td>
                <td>{tab === 0 ? v.pouch.sendBranch && v.pouch.sendBranch.branchName : v.freight.sendBranch && v.freight.sendBranch.branchName}</td>
                <td>{tab === 0 ? v.pouch.recvBranch && v.pouch.recvBranch.branchName : v.freight.recvBranch && v.freight.recvBranch.branchName}</td>
                <td>{v.regDatetime}</td>
                <td>{v.scanName}</td>
                <td>{v.addr}</td>
                <td>{GetStatus(v.status)}</td>
                <td>사진서명</td>
              </tr>
            ))}
          </tbody>
        </table>
      </SearchResultBox>
    </Layout>
  )
}

export default AppContainer(Search)

const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  margin-top: ${(props) => props.marginTop}px;
`
// TAB
const Tab = styled.button`
  background-color: ${(props) => (props.select ? "#00adee" : "#eef2f7")};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: ${WidthToDP(90)}px;
  color: ${(props) => (props.select ? "#ffffff" : "#8a8e98")};
  font-size: ${WidthToDP(32)}px;
  font-family: NanumSquareBold;
  line-height: ${WidthToDP(38.4)}px;
  letter-spacing: ${WidthToDP(-0.96)}px;
`
// SEARCH
const SearchBox = styled.div`
  background-color: #fff;
  padding: ${WidthToDP(40)}px ${WidthToDP(30)}px;
  & > p {
    color: #35363a;
    font-size: ${WidthToDP(30)}px;
    font-family: NanumSquareRegular;
    line-height: ${WidthToDP(36)}px;
    letter-spacing: ${WidthToDP(-0.9)}px;
  }
`
const ScanP = styled.p`
  margin-right: ${WidthToDP(34.2)}px;
  color: #8a8e98;
  font-size: ${WidthToDP(26)}px;
  font-family: NanumSquareBold;
  line-height: ${WidthToDP(31.2)}px;
  letter-spacing: ${WidthToDP(-0.78)}px;
`
const DateButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${WidthToDP(250)}px;
  height: ${WidthToDP(80)}px;
  padding: 0 ${WidthToDP(27)}px;
  border: 1px solid #dbdee5;
  border-radius: ${WidthToDP(12)}px;
  box-sizing: box-sizing;
  & > img {
    width: ${WidthToDP(24)}px;
    height: ${WidthToDP(26)}px;
  }
  & > p {
    color: #35363a;
    font-size: ${WidthToDP(30)}px;
    font-family: NanumSquareRegular;
    line-height: ${WidthToDP(26)}px;
  }
`
const SelectBox = styled.div`
  position: relative;
  width: ${WidthToDP(192)}px;
  height: ${WidthToDP(80)}px;
`
const SelectButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${WidthToDP(192)}px;
  height: ${WidthToDP(80)}px;
  padding: 0 ${WidthToDP(25.8)}px;
  border: 1px solid #dbdee5;
  border-top-left-radius: ${WidthToDP(12)}px;
  border-bottom-left-radius: ${WidthToDP(12)}px;
  & > p {
    overflow-x: hidden;
    color: ${(props) => props.color};
    font-size: ${WidthToDP(30)}px;
    font-family: NanumSquareRegular;
    line-height: ${WidthToDP(26)}px;
    letter-spacing: ${WidthToDP(-0.9)}px;
    text-overflow: ellipsis;
  }
  & > img {
    width: ${WidthToDP(20)}px;
    height: ${WidthToDP(10.625)}px;
  }
`
const OptionBox = styled.div`
  background-color: #fff;
  position: absolute;
  overflow-y: scroll;
  top: 38px;
  display: ${(props) => (props.open ? "block" : "none")};
  width: 100%;
  min-width: ${WidthToDP(192)}px;
  max-height: ${WidthToDP(80 * 5)}px;
  height: auto;
  border: 1px solid #dbdee5;
  border-radius: 4px;
  z-index: 100;
`
const Option = styled.button`
  overflow-x: hidden;
  display: block;
  width: ${WidthToDP(192)}px;
  height: ${WidthToDP(80)}px;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  border-top: 1px solid #dbdee5;
  box-sizing: border-box;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:first-child {
    border-top: none;
  }
`
const Input = styled.input`
  flex: 1;
  height: ${WidthToDP(80)}px;
  padding: ${WidthToDP(25.8)}px;
  border-top: 1px solid #dbdee5;
  border-bottom: 1px solid #dbdee5;
  box-sizing: border-box;
`
const SearchButton = styled.button`
  background-color: #00adee;
  width: ${WidthToDP(80)}px;
  height: ${WidthToDP(80)}px;
  border-top-right-radius: ${WidthToDP(12)}px;
  border-bottom-right-radius: ${WidthToDP(12)}px;
  & > img {
    width: ${WidthToDP(40)}px;
    height: ${WidthToDP(40)}px;
  }
`
// RESULT
const SearchResultBox = styled.div`
  background-color: #f5f6f8;
  overflow-x: auto;
  flex: 1;
`
