import React from "react"
import styled from "styled-components"
import Header from "./header"

const Layout = (props) => {
  const { children, backgroundColor = "#fff", color = "#35363a", isHeader = true, title = "", leftComponent = null, rightComponent = null } = props

  return (
    <Container backgroundColor={backgroundColor}>
      <Header
        backgroundColor={backgroundColor}
        color={color}
        isHeader={isHeader}
        title={title}
        leftComponent={leftComponent}
        rightComponent={rightComponent}
      />
      <ChildContainer>{children}</ChildContainer>
    </Container>
  )
}

export default Layout

const Container = styled.div`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const ChildContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`
