import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getUser,
  postScan,
  deleteScan,
  getScanLargePouchTracks,
  getScanPouchTracks,
  getScanFreightTracks,
  getDriverPoints,
  getFreightTracks,
  getPouchTracks,
  getPickupPouchs,
  postDeliveryComplete,
  patchDeliveryCompleteImage,

  getCustList,

} = actions.AppAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  userType: state.AppReducer.userType,
  scanLargePouchTracks: state.AppReducer.scanLargePouchTracks,
  scanPouchTracks: state.AppReducer.scanPouchTracks,
  scanFreightTracks: state.AppReducer.scanFreightTracks,
  driverPoints: state.AppReducer.driverPoints,
  freightTracks: state.AppReducer.freightTracks,
  freightTracksTotal: state.AppReducer.freightTracksTotal,
  pouchTracks: state.AppReducer.pouchTracks,
  pouchTracksTotal: state.AppReducer.pouchTracksTotal,
  pickupPouchs: state.AppReducer.pickupPouchs,

  custList: state.AppReducer.custList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getUser: (param) => dispatch(getUser(param)),
  postScan: (param) => dispatch(postScan(param)),
  deleteScan: (param) => dispatch(deleteScan(param)),
  getScanLargePouchTracks: (param) => dispatch(getScanLargePouchTracks(param)),
  getScanPouchTracks: (param) => dispatch(getScanPouchTracks(param)),
  getScanFreightTracks: (param) => dispatch(getScanFreightTracks(param)),
  getDriverPoints: (param) => dispatch(getDriverPoints(param)),
  getFreightTracks: (param) => dispatch(getFreightTracks(param)),
  getPouchTracks: (param) => dispatch(getPouchTracks(param)),
  getPickupPouchs: (param) => dispatch(getPickupPouchs(param)),
  postDeliveryComplete: (param) => dispatch(postDeliveryComplete(param)),
  patchDeliveryCompleteImage: (param) => dispatch(patchDeliveryCompleteImage(param)),

  getCustList: () => dispatch(getCustList()),
  
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
